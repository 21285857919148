<template>
	<v-row>
        <v-snackbar color="primary" v-model="snackbar.status" :timeout="snackbar.timeout" dark>
            <span class="font-weight-bold">{{snackbar.text}}</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    class="font-weight-bold"
                    color="blue-grey lighten-3"
                    text
                    v-bind="attrs"
                    @click="snackbar.status = false"
                >Fechar</v-btn>
            </template>
        </v-snackbar>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
        <v-dialog v-model="dialog.status" max-width="500">
            <v-card>
                <v-card-title>Envio de contrato assinado</v-card-title>
                <v-divider />
                <v-card-text v-if="dadosContrato.existeContrato" class="pt-5">
                    <p
                        class="font-weight-bold"
                    >Cliente: {{dadosContrato.nome}} (Pedido: {{dadosContrato.idcontrato}})</p>
                    <v-file-input
                        show-size
                        counter
                        :loading="carregando"
                        label="Contrato"
                        accept="image/png, image/jpeg, image/jpg"
                        @change="adicionarArquivo"
                    ></v-file-input>
                    <p class="mt-5 mb-0">* Tamanho máximo de 5mb por arquivo.</p>
                    <p
                        class="mb-0 red--text"
                    >** Antes de enviar o arquivo, confira se a imagem ou PDF estão legíveis e se todos os campos foram preenchidos corretamente.</p>
                </v-card-text>
                <v-card-text v-else class="pt-8">
                    <v-alert
                        type="error"
                    >{{ dialog.mensagem }}</v-alert>
                </v-card-text>
                <v-divider />
                <v-card-actions class="pa-3">
                    <v-spacer />
                    <v-btn
                        :loading="carregando"
                        text
                        color="primary"
                        elevation="0"
                        @click="dialog.status = false"
                    >Cancelar</v-btn>
                    <v-btn
                        :loading="carregando"
                        v-if="dadosContrato.existeContrato"
                        color="primary"
                        elevation="0"
                        @click="enviarContrato()"
                    >Enviar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogCloud" max-width="575" scrollable>
            <v-card>
                <v-card-title>Solicitar contrato online</v-card-title>
                <v-divider />
                <v-card-text class="black--text pt-5">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <p class="text-h6">Deseja realmente solicitar um link de contrato online?</p>
                        </v-col>
                        <v-col cols="12">
                            <p class="mb-0">
                                <strong>Cliente:</strong>
                                {{dadosContrato.nome}}
                            </p>
                        </v-col>
                        <v-col cols="12">
                            <p>
                                <strong>Pedido:</strong>
                                {{dadosContrato.idcontrato}}
                            </p>
                        </v-col>
                        <v-col cols="12" v-if="retornoOnline">
                            <v-text-field
                                ref="copiar"
                                class="mb-n5"
                                dense
                                v-model="retornoOnline"
                                outlined
                                append-icon="mdi-content-copy"
                                @click:append="copy()"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions class="pa-3">
                    <v-spacer />
                    <v-btn
                        :loading="carregando"
                        text
                        color="primary"
                        elevation="0"
                        @click="dialogCloud = false"
                    >Cancelar</v-btn>
                    <v-btn
                        :disabled="retornoOnline ? true : false"
                        :loading="carregando"
                        color="primary"
                        elevation="0"
                        @click="solicitarContratoOnline()"
                    >Solicitar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
<!--        <v-dialog v-model="dialogEncomenda" max-width="575" scrollable>-->
<!--            <v-card>-->
<!--                <v-card-title>Liberar pedido para encomenda</v-card-title>-->
<!--                <v-divider />-->
<!--                <v-card-text class="black&#45;&#45;text pt-5">-->
<!--                    <v-row no-gutters>-->
<!--                        <v-col cols="12">-->
<!--                            <p class="text-h6">Deseja realmente liberar este pedido como uma encomenda?</p>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12">-->
<!--                            <p-->
<!--                                class="mb-0"-->
<!--                            >Pedidos liberados para encomenda serão enviados para o setor de compras, aonde será realizado uma análise de reposição de mercadoria na filial.</p>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                </v-card-text>-->
<!--                <v-divider />-->
<!--                <v-card-actions class="pa-3">-->
<!--                    <v-spacer />-->
<!--                    <v-btn-->
<!--                        :loading="carregando"-->
<!--                        text-->
<!--                        color="primary"-->
<!--                        elevation="0"-->
<!--                        @click="dialogEncomenda = false"-->
<!--                    >Cancelar</v-btn>-->
<!--                    <v-btn :loading="carregando" color="primary" elevation="0" @click="contratoAutorizar()">Liberar</v-btn>-->
<!--                </v-card-actions>-->
<!--            </v-card>-->
<!--        </v-dialog>-->
		<v-col cols="12" lg="4">
			<v-card class="mx-auto">
				<v-card-title>Informações</v-card-title>
				<v-divider />
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-text>
					<div class="my-3">
						<v-icon class="mr-2">mdi-identifier</v-icon>
						<span class="font-weight-bold">Nº Pedido:</span>
						{{dados.idpedido}}
					</div>
					<div class="my-3" v-if="dados.cliente">
						<v-icon class="mr-2">mdi-account</v-icon>
						<span class="font-weight-bold">Cliente:</span>
						{{dados.cliente.nome}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-office-building</v-icon>
						<span class="font-weight-bold">Filial:</span>
						{{dados.filial}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-calendar</v-icon>
						<span class="font-weight-bold">Data do pedido:</span>
						{{dados.dtpedido}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-cash</v-icon>
						<span class="font-weight-bold">Valor:</span>
						{{dados.valortotal | formataDinheiro}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-note-text-outline</v-icon>
						<span class="font-weight-bold">Observação:</span>
						{{dados.observacao}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-octagon</v-icon>
						<span class="font-weight-bold mr-3">Status:</span>
						<v-chip v-if="dados.flagimportado == 'T'" color="success">Efetivado</v-chip>
						<v-chip v-else color="error">Pendente</v-chip>
					</div>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn
						v-if="permissao('pedido_cancelar')"
						color="error"
						elevation="0"
						@click="cancelarPedido()"
					>Cancelar</v-btn>
				</v-card-actions>
			</v-card>

            <v-card class="mx-auto mt-6">
                <v-card-title>Contratos</v-card-title>
                <v-divider />
                <v-progress-linear indeterminate absolute :active="carregando" />
                <v-card-text class="pt-0">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th width="38%" class="text-left pr-0 pl-0 pl-sm-4">
                                    Tipo do contrato
                                </th>
                                <th width="37%" class="text-center pr-2">
                                    Status
                                </th>
                                <th width="25%" class="text-center pl-0 pr-0 pr-sm-4 pr-md-0 pl-md-12 pr-lg-4 pl-lg-0">
                                    Ações
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="contratos.lista">
                            <tr class="text-no-wrap" v-for="(u,i) in contratos.lista" :key="i">
                                <td width="38%" class="pr-0 pl-0 pl-sm-4">{{ u.tipo == 'A' ? 'Aguardando' : 'A prazo'}}</td>
                                <td width="37%" v-if="u.status" class="text-truncate text-center pl-2 pr-2">
                                    <v-icon class="mr-1" color="success">mdi-check</v-icon>Enviado
                                </td>
                                <td width="37%" v-else-if="u.encomenda" class="text-truncate text-center pl-2 pr-2">
                                    <v-icon class="mr-1" color="orange">mdi-check</v-icon>Encomenda
                                </td>
                                <td width="37%" v-else class="text-truncate text-center pl-2 pr-2">
                                    <v-icon class="mr-1 mt-n1" color="red">mdi-alert-circle-outline</v-icon>Pendente
                                </td>
                                <td width="25%" class="text-right pl-0 pr-0 pr-sm-4">
                                    <v-tooltip bottom color="primary" class="text-no-wrap">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" :to="`../../../contrato/get/${idpedido}`" class="mr-1" elevation="0" color="primary" x-small>
                                                <v-icon small>mdi-magnify</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Vizualizar contrato</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="primary" class="text-no-wrap">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="mr-1" elevation="0" color="primary" x-small v-bind="attrs" v-on="on" @click="contratoValida(u)">
                                                <v-icon small>mdi-upload</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Enviar contrato salvo</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="primary" class="text-no-wrap">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn elevation="0" color="primary" x-small v-bind="attrs" v-on="on" @click="dialogContratoOnline(u)">
                                                <v-icon small>mdi-cloud-upload-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Gerar contrato online</span>
                                    </v-tooltip>
<!--                                    <v-tooltip bottom color="primary" class="text-no-wrap">-->
<!--                                        <template v-slot:activator="{ on, attrs }">-->
<!--                                            <v-btn-->
<!--                                                v-if="permissao('contrato_libera_encomenda')"-->
<!--                                                width="10px"-->
<!--                                                class="ml-1"-->
<!--                                                elevation="0"-->
<!--                                                color="primary"-->
<!--                                                x-small-->
<!--                                                v-bind="attrs"-->
<!--                                                v-on="on"-->
<!--                                                @click="dialogLiberarEncomenda(u)"-->
<!--                                            >ENC</v-btn>-->
<!--                                        </template>-->
<!--                                        <span>Liberar como encomenda</span>-->
<!--                                    </v-tooltip>-->
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="4" class="text-center">Nenhum registro encontrado.</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>
		</v-col>
		<v-col cols="12" lg="8">
			<v-row>
				<v-col cols="12">
					<Table
						:pgLimitGet="10"
						backendDirectory="pedido/produto/listar"
						:busca="{
                            idpedido: parseInt(idpedido),
                        }"
						:filter="false"
						:headers="{
                            idsubproduto: {nome: 'ID', tipo: 'texto'},
                            produto: {nome: 'Produto', tipo: 'texto'},
                            quantidade: {nome: 'Quantidade', tipo: 'texto', class: 'text-center'},
                            valortotal: {nome: 'Valor total', tipo: 'dinheiro'},
                            vendedor: {nome: 'Vendedor', tipo: 'texto'},
                            tipoentrega: {nome: 'Tipo entrega', tipo: 'texto'},
                            localretirada: {nome: 'Local retirada', tipo: 'texto'},
                            dtpreventrega: {nome: 'Data previsão entrega', tipo: 'texto'},
                        }"
					>
						<v-col>Produtos</v-col>
						<v-col cols="auto">
							<v-btn
								color="primary"
								elevation="0"
								@click="$router.push({
									name: 'VendaPedidoFaturar',
									params: {
										idorcamento: parseInt(idpedido),
									},
								})"
							>Vender novamente</v-btn>
						</v-col>
					</Table>
				</v-col>
				<v-col cols="12">
					<v-card>
						<v-card-title>Dados financeiros</v-card-title>
						<v-divider />
						<v-card-text class="pt-0">
							<v-simple-table>
								<thead>
									<tr>
										<th>Título</th>
										<th>Dígito</th>
										<th>Valor</th>
										<th>Saldo</th>
										<th>Vencimento</th>
										<th>Pagamento</th>
										<th>Forma de pagamento</th>
										<th>Prazo de pagamento</th>
									</tr>
								</thead>
								<tbody v-if="dados.recebimentos">
									<tr v-for="(u,i) in dados.recebimentos" :key="i">
										<td>{{u.idtitulo}}</td>
										<td>{{u.digitotitulo}}</td>
										<td>{{u.valtitulo | formataDinheiro}}</td>
										<td>{{u.saldo | formataDinheiro}}</td>
										<td>{{u.dtvencimento}}</td>
										<td>{{u.dtultimopagamento}}</td>
										<td>{{u.idrecebimento}} - {{u.recebimento}}</td>
										<td class="text-center">{{u.diasprazo}} dias</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="8" class="text-center">Nenhum registro encontrado.</td>
									</tr>
								</tbody>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card>
						<v-card-title>Processos</v-card-title>
						<v-divider />
						<v-card-text class="pt-0">
							<v-simple-table>
								<thead>
									<tr>
										<th>ID</th>
										<th>Processo</th>
										<th>Responsável</th>
										<th class="text-center">Status</th>
									</tr>
								</thead>
								<tbody v-if="processos">
									<tr v-for="(u,i) in processos" :key="i">
										<td>{{u.idprocesso}}</td>
										<td>{{u.processo}}</td>
										<td>{{u.responsavel}}</td>
										<td class="text-center">
											<v-chip small class="font-weight-bold">{{u.status}}</v-chip>
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="4" class="text-center">Nenhum registro encontrado.</td>
									</tr>
								</tbody>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import Table from "../Widgets/Table";
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog.vue";

export default {
	components: { Table, CardDialog },
	name: "VendaPedidoGet",
	props: ["idpedido"],
	data: () => ({
        snackbar: { status: false, timeout: 1500 },
		carregando: false,
		dados: {},
        contratos:{},
        contratosPrazo:{},
		produtos: {},
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
        dadosContrato: { existeContrato: false, tipocontrato:'', idcontrato: 0 },
        arquivos: [],
		processos: [],
        dialog: {status:false, mensagem:''},
        dialogCloud: false,
        dialogLoading: false,
        // dialogEncomenda: false,
        retornoOnline: "",
	}),
	computed: {
		...mapState(["backendUrl"]),
	},
	methods: {
		get() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}pedido/get`, {
					idpedido: parseInt(this.idpedido),
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		cancelarPedido() {
			return axios
				.post(`${this.backendUrl}venda/pedido/cancelar`, {
					idpedido: parseInt(this.idpedido),
					idempresa: parseInt(this.dados.idfilial),
				})
				.then((res) => {
					if (res.data) {
						this.dialogErro.title = "Sucesso";
						this.dialogErro.msg = "Pedido cancelado com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
						setTimeout(() => {
							this.$router.push(`/venda/pedido`);
						}, 2000);
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg =
								"Não foi possível excluir o pedido!";
						} else {
							this.dialogErro.msg = res.data.erro;
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.dialogErro.title = "Erro";
					this.dialogErro.msg = "Não foi possível excluir o pedido!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
        listarContratos() {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}pedido/contrato/listar`, {
                    idfilial: this.dados.idfilial,
                    idcontrato: parseInt(this.idpedido)
                })
                .then((res) => {
                    this.contratos = res.data;
                    this.carregando = false;
                    this.carregandoSkeleton = false;
                })
                .catch(() => {
                    this.dados = { total: 0 };
                    this.carregando = false;
                    this.carregandoSkeleton = false;
                });
        },
        contratoValida(contrato) {
            this.arquivos = [];
            this.dadosContrato = { existeContrato: false };
            return axios
                .post(`${this.backendUrl}contrato/valida`, {
                    idcontrato: parseInt(contrato.idcontrato),
                    tipocontrato: contrato.tipo,
                })
                .then((res) => {
                    if (res.data) {
                        this.dadosContrato = {existeContrato : true, tipocontrato : contrato.tipo, idcontrato: contrato.idcontrato};
                    } else {
                        this.dadosContrato = { existeContrato: false };
                        this.dialog.mensagem = `Pedido não teve contrato gerado no CISS. Para gerar contrato deve ser gerado no relatório ${contrato.tipo == 'A'?'150389':'150318'}`
                    }
                    this.dialog.status = true;
                })
                .catch(() => {
                    this.dadosContrato = { existeContrato: false };
                    this.dialog.status = true;
                });
        },
        enviarContrato() {
            if (this.arquivos.length > 0) {
                this.carregando = true;
            }
            this.dialog.status = false;
            return axios
                .post(`${this.backendUrl}contrato/envia`, {
                    idcontrato: parseInt(this.dadosContrato.idcontrato),
                    tipo: this.arquivos[0].tipo,
                    arquivo: this.arquivos[0].arquivo,
                    tipocontrato: this.dadosContrato.tipocontrato,
                })
                .then((res) => {
                    if (res.data) {
                        this.$router.push(`/contrato/get/${this.dadosContrato.idcontrato}`
                        );
                    } else {
                        this.dialogErro.title = "Erro";
                        if (res.data.erro) {
                            this.dialogErro.msg =
                                "Não foi possível realizar o cadastro do contrato!";
                        } else {
                            this.dialogErro.msg = res.data.erro;
                        }
                        this.dialogErro.icon = "mdi-alert-circle-outline";
                        this.dialogErro.iconColor = "red";
                        this.dialogErro.status = true;
                    }
                })
                .catch((e) => {
                    this.dialogErro.title = "Erro";
                    if (e.response.data.erro) {
                        this.dialogErro.msg = e.response.data.erro;
                    } else {
                        this.dialogErro.msg =
                            "Não foi possível realizar o cadastro do contrato!";
                    }
                    this.dialogErro.icon = "mdi-alert-circle-outline";
                    this.dialogErro.iconColor = "red";
                    this.dialogErro.status = true;
                });
        },
        dialogContratoOnline(contrato) {
            this.retornoOnline = "";
            this.dadosContrato = { existeContrato: false };
            this.dadosContrato = contrato;
            this.dadosContrato.existeContrato = true;
            this.dialogCloud = true;
        },
        solicitarContratoOnline() {
            this.dialogLoading = true;
            return axios
                .post(`${this.backendUrl}contrato/online/teste`, {
                    idcontrato: parseInt(this.dadosContrato.idcontrato),
                    tipo: this.dadosContrato.tipo,
                })
                .then((res) => {
                    this.dialogLoading = false;
                    if (res.data) {
                        this.retornoOnline = res.data.url;
                    } else {
                        this.dialogErro.title = "Erro";
                        if (res.data.erro) {
                            this.dialogErro.msg =
                                "Não foi possível realizar o envio do contrato!";
                        } else {
                            this.dialogErro.msg = res.data.erro;
                        }
                        this.dialogErro.icon = "mdi-alert-circle-outline";
                        this.dialogErro.iconColor = "red";
                        this.dialogErro.status = true;
                    }
                })
                .catch((e) => {
                    this.dialogLoading = false;
                    this.dialogErro.title = "Erro";
                    if (
                        e.response.data.erro ==
                        "pedido aguardando liberação da analise de credito"
                    ) {
                        this.dialogErro.msg =
                            "Pedido em análise de crédito! Aguarde a liberação.";
                    } else {
                        this.dialogErro.msg =
                            "Não foi possível realizar o envio do contrato!";
                    }
                    this.dialogErro.icon = "mdi-alert-circle-outline";
                    this.dialogErro.iconColor = "red";
                    this.dialogErro.status = true;
                });
        },
        listaProcessos() {
            return axios
                .post(`${this.backendUrl}pedido/processo`, {
                    idorcamento: parseInt(this.idpedido),
                })
                .then((res) => {
                    this.processos = res.data;
                });
        },
        adicionarArquivo(arquivos) {
            let obj = {};
            obj.tipo =
                arquivos.name.split(".")[arquivos.name.split(".").length - 1];
            let reader = new FileReader();
            reader.readAsDataURL(arquivos);
            reader.onload = function () {
                obj.arquivo = reader.result.split("base64,")[1];
            };
            this.arquivos.push(obj);
        },
        copy() {
            var copyTextarea = this.$refs.copiar.$refs.input;
            copyTextarea.select();
            try {
                var successful = document.execCommand("copy");
                var msg = successful ? "sim!" : "não!";
                if (successful) {
                    msg = `"${this.retornoOnline}" foi copiado para sua área de transferência`;
                } else {
                    msg =
                        "Não foi possível copiar o texto, use o atalho Crtl+C.";
                }
                this.snackbar.text = msg;
                this.snackbar.status = true;
            } catch (err) {
                this.snackbar.text =
                    "Não foi possível copiar o texto, use o atalho Crtl+C.";
                this.snackbar.status = true;
            }
        },
        // dialogLiberarEncomenda(contrato) {
        //     this.dadosContrato = contrato;
        //     this.dialogEncomenda = true;
        // },
        // contratoAutorizar() {
        //     this.carregando = true;
        //     return axios
        //         .post(`${this.backendUrl}contrato/autorizar`, {
        //             idcontrato: parseInt(this.dadosContrato.idcontrato),
        //         })
        //         .then((res) => {
        //             this.dialogEncomenda = false;
        //             if (res.data) {
        //                 this.$router.push(
        //                     `/contrato/get/${this.dadosContrato.idcontrato}`
        //                 );
        //             } else {
        //                 this.dialogErro.title = "Erro";
        //                 if (res.data.erro) {
        //                     this.dialogErro.msg =
        //                         "Não foi possível liberar o pedido!";
        //                 } else {
        //                     this.dialogErro.msg = res.data.erro;
        //                 }
        //                 this.dialogErro.icon = "mdi-alert-circle-outline";
        //                 this.dialogErro.iconColor = "red";
        //                 this.dialogErro.status = true;
        //             }
        //         })
        //         .catch((e) => {
        //             this.dialogEncomenda = false;
        //             this.dialogErro.title = "Erro";
        //             if (e.response.data.erro) {
        //                 this.dialogErro.msg = e.response.data.erro;
        //             } else {
        //                 this.dialogErro.msg =
        //                     "Não foi possível liberar o pedido!";
        //             }
        //             this.dialogErro.icon = "mdi-alert-circle-outline";
        //             this.dialogErro.iconColor = "red";
        //             this.dialogErro.status = true;
        //         });
        // },
		async init() {
			await this.get();
            await this.listaProcessos();
            await this.listarContratos();
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>